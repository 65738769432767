import React from 'react'
import styled from '@emotion/styled'

const Logo = props => {
  return (
    <svg width="120" height="61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M120 43.8323H0v5.2596h120v-5.2596z" fill="#5D4229" />
      <path
        d="M105.375 25.1251l12.452 3.3321c.599.0703 1.151.3582 1.552.8091.4.4509.622 1.0332.621 1.6364v12.9297H0V26.7108L2.625.00007099 93 0c.5206-.00000398 1.5321.121665 1.9973.355296.4653.23363.8695.572758 1.1804.990324l9.1973 23.77948z"
        fill="#8A6E50"
      />
      <path
        d="M101.654 37.0107c-1.585-.0003-3.1547.3115-4.619.9177-1.4643.6063-2.7949 1.495-3.9157 2.6156-1.1208 1.1205-2.0099 2.4508-2.6165 3.915-.6066 1.4642-.9188 3.0336-.9188 4.6184h24.139c-.001-3.2003-1.274-6.269-3.537-8.5317-2.263-2.2627-5.332-3.5342-8.532-3.535z"
        fill="#2C3A41"
      />
      <path
        d="M101.656 59.813c5.929 0 10.735-4.8066 10.735-10.7357 0-5.9292-4.806-10.7357-10.735-10.7357-5.9295 0-10.7361 4.8065-10.7361 10.7357 0 5.9291 4.8066 10.7357 10.7361 10.7357z"
        fill="#3A4D57"
      />
      <path
        d="M105.243 52.6662c1.982-1.9821 1.982-5.1956 0-7.1776-1.982-1.982-5.196-1.982-7.1776 0-1.9821 1.982-1.9821 5.1956 0 7.1776 1.9816 1.982 5.1956 1.982 7.1776 0z"
        fill="#fff"
      />
      <path
        d="M19.1536 37.0107c-3.2003.0008-6.2694 1.2723-8.5326 3.535-2.26326 2.2627-3.53546 5.3314-3.53702 8.5317H31.2232c0-1.5848-.3122-3.1542-.9188-4.6184-.6066-1.4642-1.4957-2.7945-2.6165-3.915-1.1208-1.1206-2.4513-2.0093-3.9157-2.6156-1.4643-.6062-3.0337-.918-4.6186-.9177z"
        fill="#2C3A41"
      />
      <path
        d="M19.1517 59.813c5.9292 0 10.7357-4.8066 10.7357-10.7357 0-5.9292-4.8065-10.7357-10.7357-10.7357-5.9291 0-10.73568 4.8065-10.73568 10.7357 0 5.9291 4.80658 10.7357 10.73568 10.7357z"
        fill="#3A4D57"
      />
      <path
        d="M24.09 50.2417c.6439-2.7281-1.0457-5.4616-3.7738-6.1055-2.728-.6438-5.4615 1.0457-6.1054 3.7738-.6439 2.7281 1.0457 5.4616 3.7737 6.1055 2.7281.6438 5.4616-1.0457 6.1055-3.7738z"
        fill="#fff"
      />
      <path
        d="M75.7549 28.908H71.25v1.015h4.5049v-1.015zM119.262 32.1606h-2.616v4.5137h2.616v-4.5137zM3.61714 36.6714H1.00195v4.5137h2.61519v-4.5137z"
        fill="#F9F8F7"
      />
      <path
        d="M73.238 3.36663c-1.1011.00439-1.988.89828-1.988 1.99933V22.0245c0 1.0981.8853 1.9908 1.9833 2l25.6795.2149c.295.0006.5845-.0795.8372-.2315.253-.1521.459-.3703.597-.6312.137-.2609.201-.5544.184-.8488-.017-.2945-.114-.5786-.281-.8218L93.375 3.37504c-.0477-.08106-14.7111-.03006-20.137-.00841zM101.937 60.0385c5.929 0 10.736-4.8065 10.736-10.7357 0-5.9291-4.807-10.7357-10.736-10.7357-5.9293 0-10.7358 4.8066-10.7358 10.7357 0 5.9292 4.8065 10.7357 10.7358 10.7357z"
        fill="#3A4D57"
      />
      <path
        d="M101.936 56.2619c3.843 0 6.959-3.1157 6.959-6.9592s-3.116-6.9592-6.959-6.9592c-3.8437 0-6.9594 3.1157-6.9594 6.9592s3.1157 6.9592 6.9594 6.9592z"
        fill="#BFC1BF"
      />
      <path
        d="M102.413 48.352h-.954l-.4-5.0139h1.755l-.401 5.0139z"
        fill="#3A4D57"
      />
      <path
        d="M101.6 48.2935l-.673.6728-3.8293-3.2616 1.2403-1.2403 3.262 3.8291z"
        fill="#3A4D57"
      />
      <path
        d="M100.985 48.8258v.9536l-5.0143.4008V48.425l5.0143.4008z"
        fill="#3A4D57"
      />
      <path
        d="M100.927 49.6389l.673.6728-3.262 3.8292-1.2403-1.2403 3.8293-3.2617z"
        fill="#3A4D57"
      />
      <path
        d="M101.459 50.2563h.954l.401 5.011h-1.755l.4-5.011z"
        fill="#3A4D57"
      />
      <path
        d="M102.273 50.3117l.673-.6728 3.829 3.2617-1.24 1.2403-3.262-3.8292z"
        fill="#3A4D57"
      />
      <path
        d="M102.887 49.7794v-.9536l5.014-.4008v1.7552l-5.014-.4008z"
        fill="#3A4D57"
      />
      <path
        d="M102.946 48.9663l-.673-.6728 3.262-3.8291 1.24 1.2403-3.829 3.2616zM19.1517 60.0385c5.9292 0 10.7357-4.8065 10.7357-10.7357 0-5.9291-4.8065-10.7357-10.7357-10.7357-5.9291 0-10.73568 4.8066-10.73568 10.7357 0 5.9292 4.80658 10.7357 10.73568 10.7357z"
        fill="#3A4D57"
      />
      <path
        d="M19.1526 56.2619c3.8434 0 6.9591-3.1157 6.9591-6.9592s-3.1157-6.9592-6.9591-6.9592c-3.8435 0-6.9592 3.1157-6.9592 6.9592s3.1157 6.9592 6.9592 6.9592z"
        fill="#BFC1BF"
      />
      <path
        d="M19.6298 48.352h-.9536l-.4008-5.0139h1.7551l-.4007 5.0139z"
        fill="#3A4D57"
      />
      <path
        d="M18.8164 48.2935l-.6728.6728-3.8291-3.2616 1.2403-1.2403 3.2616 3.8291z"
        fill="#3A4D57"
      />
      <path
        d="M18.2014 48.8258v.9536l-5.0139.4008V48.425l5.0139.4008z"
        fill="#3A4D57"
      />
      <path
        d="M18.1436 49.6389l.6728.6728-3.2616 3.8292-1.2403-1.2403 3.8291-3.2617z"
        fill="#3A4D57"
      />
      <path
        d="M18.6762 50.2563h.9536l.4007 5.011h-1.7551l.4008-5.011z"
        fill="#3A4D57"
      />
      <path
        d="M19.4883 50.3117l.6728-.6728 3.8292 3.2617-1.2404 1.2403-3.2616-3.8292z"
        fill="#3A4D57"
      />
      <path
        d="M20.1035 49.7794v-.9536l5.0139-.4008v1.7552l-5.0139-.4008z"
        fill="#3A4D57"
      />
      <path
        d="M20.1611 48.9663l-.6728-.6728 3.2616-3.8291 1.2404 1.2403-3.8292 3.2616z"
        fill="#3A4D57"
      />
    </svg>
  )
}

export default Logo
