import React from 'react'

const Logo = props => {
  return (
    <svg
      width="120"
      height="53"
      viewBox="0 0 120 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M120 35.9573H0V41.2169H120V35.9573Z" fill="#B58649" />
        <path
          d="M120 23.0277V35.9573H0V16.4751L2.06816 2.17639C2.15538 1.57213 2.4574 1.01953 2.91889 0.619821C3.38038 0.220113 3.97043 6.87031e-05 4.58096 3.34754e-07H69.6651C70.1857 -0.000232823 70.6992 0.121333 71.1644 0.354982C71.6297 0.588631 72.0339 0.927883 72.3446 1.34562L83.7004 16.557L117.827 20.5821C118.426 20.6524 118.978 20.9404 119.379 21.3912C119.779 21.8421 120.001 22.4245 120 23.0277ZM77.5516 16.0889C77.7897 16.09 78.0233 16.0245 78.2261 15.8996C78.4289 15.7748 78.5926 15.5957 78.6989 15.3826C78.8051 15.1695 78.8496 14.9309 78.8272 14.6939C78.8049 14.4568 78.7167 14.2307 78.5725 14.0413L70.253 2.90478C70.1848 2.81413 70.0964 2.74053 69.9949 2.68977C69.8934 2.63902 69.7815 2.61248 69.668 2.61226H4.64531L2.92526 14.4215C2.89577 14.6301 2.91165 14.8425 2.97183 15.0443C3.032 15.2461 3.13505 15.4326 3.27393 15.5909C3.41281 15.7492 3.58423 15.8757 3.77649 15.9616C3.96875 16.0476 4.1773 16.091 4.38789 16.0889H77.5516Z"
          fill="#F2B361"
        />
        <path
          d="M78.5726 14.0413C78.7168 14.2308 78.8051 14.4569 78.8274 14.6939C78.8497 14.931 78.8053 15.1696 78.699 15.3827C78.5928 15.5957 78.429 15.7748 78.2262 15.8997C78.0235 16.0245 77.7898 16.0901 77.5517 16.089H4.38804C4.17689 16.0912 3.96776 16.0476 3.77503 15.9613C3.5823 15.875 3.41054 15.748 3.27157 15.589C3.13259 15.43 3.02969 15.2428 2.96994 15.0403C2.91018 14.8378 2.89499 14.6247 2.92541 14.4157L4.64546 2.6123H69.6652C69.7787 2.61253 69.8906 2.63906 69.9921 2.68982C70.0936 2.74057 70.182 2.81417 70.2503 2.90483L78.5726 14.0413Z"
          fill="#F9F8F7"
        />
        <path
          d="M19.5905 16.4956H18.7129V35.9574H19.5905V16.4956Z"
          fill="#B58649"
        />
        <path
          d="M101.656 29.1355C100.071 29.1351 98.5013 29.4469 97.0369 30.0532C95.5726 30.6594 94.242 31.5482 93.1212 32.6687C92.0004 33.7892 91.1113 35.1196 90.5047 36.5838C89.8981 38.048 89.5859 39.6173 89.5859 41.2022H113.725C113.724 38.0019 112.451 34.9331 110.188 32.6704C107.925 30.4077 104.856 29.1363 101.656 29.1355Z"
          fill="#2C3A41"
        />
        <path
          d="M112.1 43.9535C113.462 38.1829 109.888 32.4008 104.117 31.0388C98.3465 29.6768 92.5644 33.2507 91.2024 39.0213C89.8404 44.792 93.4143 50.5741 99.1849 51.9361C104.956 53.2981 110.738 49.7242 112.1 43.9535Z"
          fill="#3A4D57"
        />
        <path
          d="M107.215 42.8005C107.94 39.728 106.037 36.6495 102.965 35.9243C99.8921 35.1992 96.8136 37.102 96.0884 40.1744C95.3632 43.2469 97.2661 46.3254 100.338 47.0506C103.411 47.7757 106.489 45.8729 107.215 42.8005Z"
          fill="#BFC1BF"
        />
        <path
          d="M102.048 40.7108H101.264L100.992 37.3117C100.985 37.22 100.997 37.1278 101.027 37.0409C101.057 36.954 101.105 36.8743 101.167 36.8067C101.229 36.7391 101.305 36.6852 101.389 36.6482C101.473 36.6113 101.564 36.5922 101.656 36.592C101.748 36.5921 101.839 36.6113 101.923 36.6482C102.007 36.6852 102.083 36.7392 102.145 36.8068C102.207 36.8745 102.254 36.9542 102.284 37.0411C102.314 37.128 102.325 37.2201 102.317 37.3117L102.048 40.7108Z"
          fill="#3A4D57"
        />
        <path
          d="M101.378 40.661L100.825 41.2139L98.2301 39.0053C98.1601 38.9458 98.1032 38.8724 98.063 38.7897C98.0228 38.7071 98.0002 38.617 97.9966 38.5251C97.993 38.4333 98.0086 38.3417 98.0422 38.2562C98.0759 38.1707 98.1269 38.0931 98.1921 38.0283C98.2569 37.9631 98.3345 37.912 98.42 37.8784C98.5055 37.8447 98.5971 37.8292 98.689 37.8328C98.7808 37.8364 98.8709 37.859 98.9535 37.8992C99.0362 37.9393 99.1096 37.9963 99.1691 38.0663L101.378 40.661Z"
          fill="#3A4D57"
        />
        <path
          d="M100.875 41.0998V41.8809L97.4755 42.1529C97.384 42.1606 97.2918 42.1492 97.205 42.1195C97.1181 42.0898 97.0383 42.0423 96.9706 41.9802C96.903 41.9181 96.849 41.8426 96.812 41.7585C96.7751 41.6745 96.7559 41.5836 96.7559 41.4918C96.756 41.3998 96.7751 41.3089 96.812 41.2247C96.849 41.1405 96.9029 41.0648 96.9705 41.0025C97.0381 40.9401 97.1178 40.8924 97.2047 40.8623C97.2916 40.8323 97.3838 40.8205 97.4755 40.8278L100.875 41.0998Z"
          fill="#3A4D57"
        />
        <path
          d="M100.824 41.7668L101.377 42.3197L99.1652 44.9173C99.1057 44.9874 99.0323 45.0443 98.9496 45.0845C98.867 45.1247 98.7769 45.1473 98.6851 45.1509C98.5932 45.1544 98.5016 45.1389 98.4161 45.1053C98.3306 45.0716 98.253 45.0206 98.1882 44.9554C98.123 44.8906 98.0719 44.813 98.0383 44.7275C98.0046 44.6419 97.9891 44.5503 97.9927 44.4585C97.9963 44.3667 98.0189 44.2766 98.0591 44.1939C98.0993 44.1113 98.1562 44.0379 98.2262 43.9783L100.824 41.7668Z"
          fill="#3A4D57"
        />
        <path
          d="M101.264 42.2729H102.048L102.317 45.6721C102.325 45.7635 102.314 45.8555 102.284 45.9422C102.254 46.0289 102.207 46.1085 102.144 46.1759C102.082 46.2433 102.007 46.2971 101.923 46.3337C101.839 46.3704 101.748 46.3891 101.656 46.3888C101.564 46.3886 101.474 46.3696 101.39 46.3328C101.306 46.2961 101.23 46.2424 101.168 46.1751C101.106 46.1079 101.058 46.0285 101.028 45.9419C100.997 45.8554 100.985 45.7635 100.992 45.6721L101.264 42.2729Z"
          fill="#3A4D57"
        />
        <path
          d="M101.932 42.3197L102.485 41.7668L105.082 43.9783C105.152 44.0379 105.209 44.1113 105.249 44.1939C105.29 44.2766 105.312 44.3667 105.316 44.4585C105.319 44.5503 105.304 44.6419 105.27 44.7275C105.236 44.813 105.185 44.8906 105.12 44.9554C105.055 45.0206 104.978 45.0716 104.892 45.1053C104.807 45.1389 104.715 45.1544 104.623 45.1509C104.531 45.1473 104.441 45.1247 104.359 45.0845C104.276 45.0443 104.203 44.9874 104.143 44.9173L101.932 42.3197Z"
          fill="#3A4D57"
        />
        <path
          d="M102.438 41.8809V41.0998L105.837 40.8278C105.928 40.8209 106.02 40.833 106.107 40.8633C106.193 40.8936 106.272 40.9413 106.34 41.0036C106.407 41.066 106.461 41.1415 106.497 41.2255C106.534 41.3095 106.553 41.4001 106.553 41.4918C106.553 41.5834 106.534 41.6739 106.497 41.7578C106.461 41.8416 106.407 41.917 106.34 41.9791C106.272 42.0412 106.193 42.0887 106.106 42.1186C106.02 42.1485 105.928 42.1602 105.837 42.1529L102.438 41.8809Z"
          fill="#3A4D57"
        />
        <path
          d="M102.485 41.2139L101.932 40.661L104.143 38.0663C104.203 37.9963 104.276 37.9393 104.359 37.8992C104.441 37.859 104.531 37.8364 104.623 37.8328C104.715 37.8292 104.807 37.8447 104.892 37.8784C104.978 37.912 105.055 37.9631 105.12 38.0283C105.185 38.0931 105.236 38.1707 105.27 38.2562C105.304 38.3417 105.319 38.4333 105.316 38.5251C105.312 38.617 105.29 38.7071 105.249 38.7897C105.209 38.8724 105.152 38.9458 105.082 39.0053L102.485 41.2139Z"
          fill="#3A4D57"
        />
        <path
          d="M19.1517 29.1355C15.9513 29.1363 12.8823 30.4077 10.619 32.6704C8.35579 34.9331 7.08358 38.0019 7.08203 41.2022H31.2213C31.2213 39.6173 30.9091 38.048 30.3025 36.5838C29.6959 35.1196 28.8068 33.7892 27.686 32.6687C26.5652 31.5482 25.2346 30.6594 23.7703 30.0532C22.3059 29.4469 20.7365 29.1351 19.1517 29.1355Z"
          fill="#2C3A41"
        />
        <path
          d="M19.1517 51.9497C25.0809 51.9497 29.8874 47.1431 29.8874 41.214C29.8874 35.2848 25.0809 30.4783 19.1517 30.4783C13.2226 30.4783 8.41602 35.2848 8.41602 41.214C8.41602 47.1431 13.2226 51.9497 19.1517 51.9497Z"
          fill="#3A4D57"
        />
        <path
          d="M19.1515 46.93C22.3083 46.93 24.8675 44.3708 24.8675 41.214C24.8675 38.0572 22.3083 35.498 19.1515 35.498C15.9947 35.498 13.4355 38.0572 13.4355 41.214C13.4355 44.3708 15.9947 46.93 19.1515 46.93Z"
          fill="#BFC1BF"
        />
        <path
          d="M19.5442 40.433H18.7602L18.4882 37.0338C18.4813 36.9424 18.4934 36.8505 18.5237 36.764C18.554 36.6774 18.6017 36.5981 18.664 36.5308C18.7264 36.4635 18.8019 36.4099 18.8859 36.3731C18.9699 36.3363 19.0605 36.3173 19.1522 36.3171C19.2439 36.3173 19.3346 36.3363 19.4186 36.3731C19.5026 36.4099 19.5781 36.4635 19.6404 36.5308C19.7027 36.5981 19.7505 36.6774 19.7807 36.764C19.811 36.8505 19.8231 36.9424 19.8163 37.0338L19.5442 40.433Z"
          fill="#3A4D57"
        />
        <path
          d="M18.8767 40.3862L18.3238 40.9537L15.7262 38.7422C15.6562 38.6827 15.5993 38.6093 15.5591 38.5267C15.5189 38.444 15.4963 38.3539 15.4927 38.2621C15.4891 38.1702 15.5046 38.0787 15.5383 37.9931C15.5719 37.9076 15.623 37.83 15.6882 37.7652C15.7532 37.7002 15.8309 37.6494 15.9166 37.616C16.0022 37.5825 16.0938 37.5673 16.1857 37.5711C16.2775 37.575 16.3676 37.5979 16.4501 37.6383C16.5327 37.6788 16.6059 37.7359 16.6652 37.8062L18.8767 40.3862Z"
          fill="#3A4D57"
        />
        <path
          d="M18.3707 40.8249V41.606L14.9716 41.878C14.8799 41.8853 14.7877 41.8735 14.7008 41.8434C14.6139 41.8134 14.5342 41.7656 14.4666 41.7033C14.399 41.6409 14.3451 41.5653 14.3081 41.4811C14.2712 41.3969 14.2521 41.3059 14.252 41.214C14.2525 41.1223 14.2719 41.0317 14.309 40.9478C14.3461 40.8639 14.4001 40.7886 14.4677 40.7266C14.5352 40.6645 14.6148 40.6171 14.7015 40.5872C14.7882 40.5573 14.8801 40.5456 14.9716 40.5529L18.3707 40.8249Z"
          fill="#3A4D57"
        />
        <path
          d="M18.3229 41.4917L18.8758 42.0446L16.673 44.6393C16.6135 44.7091 16.5401 44.7659 16.4576 44.8061C16.3751 44.8463 16.2852 44.8691 16.1934 44.8729C16.1017 44.8768 16.0102 44.8616 15.9246 44.8285C15.839 44.7953 15.7612 44.7449 15.696 44.6802C15.6308 44.6155 15.5798 44.5378 15.5461 44.4523C15.5125 44.3668 15.4969 44.2752 15.5005 44.1834C15.5041 44.0915 15.5267 44.0014 15.5669 43.9188C15.6071 43.8361 15.664 43.7627 15.734 43.7032L18.3229 41.4917Z"
          fill="#3A4D57"
        />
        <path
          d="M18.7602 41.998H19.5442L19.8163 45.3972C19.8231 45.4886 19.811 45.5805 19.7807 45.667C19.7505 45.7536 19.7027 45.833 19.6404 45.9002C19.5781 45.9675 19.5026 46.0212 19.4186 46.0579C19.3346 46.0947 19.2439 46.1137 19.1522 46.1139C19.0605 46.1137 18.9699 46.0947 18.8859 46.0579C18.8019 46.0212 18.7264 45.9675 18.664 45.9002C18.6017 45.833 18.554 45.7536 18.5237 45.667C18.4934 45.5805 18.4813 45.4886 18.4882 45.3972L18.7602 41.998Z"
          fill="#3A4D57"
        />
        <path
          d="M19.4297 42.0446L19.9826 41.4917L22.5773 43.7032C22.6473 43.7627 22.7042 43.8361 22.7444 43.9188C22.7846 44.0014 22.8072 44.0915 22.8108 44.1834C22.8144 44.2752 22.7988 44.3668 22.7652 44.4523C22.7315 44.5378 22.6805 44.6155 22.6153 44.6802C22.5502 44.7434 22.473 44.7927 22.3882 44.825C22.3034 44.8574 22.213 44.8721 22.1223 44.8682C22.0317 44.8644 21.9428 44.8421 21.8611 44.8028C21.7794 44.7634 21.7065 44.7078 21.647 44.6393L19.4297 42.0446Z"
          fill="#3A4D57"
        />
        <path
          d="M19.9316 41.606V40.8249L23.3308 40.5529C23.4223 40.5452 23.5144 40.5566 23.6013 40.5863C23.6882 40.616 23.768 40.6634 23.8356 40.7256C23.9032 40.7877 23.9573 40.8632 23.9942 40.9473C24.0312 41.0313 24.0503 41.1221 24.0504 41.214C24.0507 41.3061 24.0319 41.3972 23.9951 41.4816C23.9583 41.566 23.9044 41.6419 23.8367 41.7043C23.769 41.7668 23.6891 41.8145 23.602 41.8444C23.5149 41.8743 23.4226 41.8857 23.3308 41.878L19.9316 41.606Z"
          fill="#3A4D57"
        />
        <path
          d="M19.9826 40.9537L19.4297 40.4008L21.647 37.7915C21.7063 37.7213 21.7796 37.6641 21.8621 37.6237C21.9447 37.5832 22.0347 37.5603 22.1266 37.5565C22.2184 37.5526 22.31 37.5679 22.3957 37.6013C22.4813 37.6347 22.5591 37.6855 22.6241 37.7506C22.6893 37.8153 22.7403 37.893 22.774 37.9785C22.8076 38.064 22.8231 38.1556 22.8196 38.2474C22.816 38.3393 22.7934 38.4294 22.7532 38.512C22.713 38.5947 22.6561 38.6681 22.586 38.7276L19.9826 40.9537Z"
          fill="#3A4D57"
        />
        <path
          d="M48.0267 1.93066H46.3359V16.4955H48.0267V1.93066Z"
          fill="#F2B361"
        />
        <path
          d="M19.9974 1.93066H18.3066V16.4955H19.9974V1.93066Z"
          fill="#F2B361"
        />
        <path
          d="M54.4854 19.4238H49.9805V20.4389H54.4854V19.4238Z"
          fill="#F9F8F7"
        />
        <path
          d="M39.876 20.4358H44.3809V19.4207H39.876V20.4358Z"
          fill="#F9F8F7"
        />
        <path
          d="M47.6217 16.4956H46.7441V35.9574H47.6217V16.4956Z"
          fill="#B58649"
        />
        <path
          d="M79.2682 16.4956H78.3906V35.9574H79.2682V16.4956Z"
          fill="#B58649"
        />
        <path
          d="M119.264 24.2854H116.648V28.7991H119.264V24.2854Z"
          fill="#F9F8F7"
        />
        <path d="M3.61518 28.7964H1V33.3101H3.61518V28.7964Z" fill="#F9F8F7" />
      </g>
    </svg>
  )
}

export default Logo
