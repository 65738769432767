import React from 'react'
import styled from '@emotion/styled'

const Arrow = props => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.1613 0.816051C15.8344 0.477658 15.2902 0.477658 14.9518 0.816051C14.6248 1.14301 14.6248 1.68718 14.9518 2.01338L21.0848 8.14638H0.846745C0.374976 8.14714 0 8.52212 0 8.99389C0 9.46566 0.374976 9.85283 0.846745 9.85283H21.0848L14.9518 15.9744C14.6248 16.3128 14.6248 16.8577 14.9518 17.1839C15.2902 17.5223 15.8351 17.5223 16.1613 17.1839L23.7462 9.59903C24.0846 9.27207 24.0846 8.7279 23.7462 8.4017L16.1613 0.816051Z" />
    </svg>
  )
}

export default Arrow
