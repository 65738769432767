import React from 'react'
import styled from '@emotion/styled'

const Logo = props => {
  return (
    <svg width="120" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M120 31.5608H0v5.2596h120v-5.2596z" fill="#CC3E27" />
      <path
        d="M120 23.9111v7.6495H.0000019v-14.009c-.00038504-.2975.0579162-.5921.1715641-.8669.113648-.2749.280409-.5247.490732-.735.210322-.2103.460072-.3771.734942-.4907.27488-.1137.56947-.172.86691-.1716H13.231L24.7652 2.10034c.5763-.65926 1.2868-1.187719 2.0839-1.549961C27.6462.188138 28.5116.00048496 29.3872 0h34.9129c1.5116.00100345 2.9697.559248 4.0954 1.56794l15.302 13.71366 33.0205 4.8266c.912.1342 1.746.5923 2.349 1.2905.602.6983.934 1.59.933 2.5124zm-44.97-8.6705c.3645-.0008.7201-.112 1.0202-.3189.3001-.2068.5305-.4997.6609-.8401.1305-.3403.1548-.7121.0699-1.0666-.085-.3544-.2753-.6748-.5458-.9191l-9.5773-8.58559c-.6488-.57904-1.4882-.89875-2.3578-.89805H29.3872c-.5033-.00125-1.0009.10572-1.4592.31366-.4583.20795-.8665.512-1.197.89154l-7.8806 9.00684c-.1817.2113-.2993.4701-.3389.7459-.0396.2759.0004.5573.1154.8111.1149.2539.3.4696.5334.6219.2334.1522.5055.2346.7841.2374H75.03z"
        fill="#F16935"
      />
      <path
        d="M76.2366 12.096c.2705.2442.4608.5646.5458.9191.0849.3544.0606.7263-.0699 1.0666-.1304.3403-.3608.6332-.6609.8401-.3001.2068-.6558.318-1.0202.3189H19.9458c-.2787-.0029-.5507-.0853-.7841-.2375-.2334-.1522-.4185-.3679-.5334-.6218-.115-.2539-.155-.5353-.1154-.8111.0396-.2758.1571-.5346.3389-.7459l7.8806-9.00688c.3305-.37954.7387-.68359 1.197-.89154.4582-.20794.9559-.31491 1.4591-.31366h34.913c.8696-.0007 1.709.31901 2.3578.89805l9.5773 8.58563z"
        fill="#F9F8F7"
      />
      <path
        d="M101.431 24.572c-3.2011 0-6.2711 1.2716-8.5346 3.5351-2.2635 2.2635-3.5351 5.3335-3.5351 8.5345h24.1397c0-3.201-1.272-6.271-3.536-8.5345-2.263-2.2635-5.333-3.5351-8.534-3.5351zM16.0267 24.572c-3.2011 0-6.27106 1.2716-8.53456 3.5351-2.26349 2.2635-3.53511 5.3335-3.53511 8.5345H28.0816c.0008-3.1987-1.2685-6.2669-3.529-8.5302-2.2605-2.2632-5.3272-3.5363-8.5259-3.5394z"
        fill="#2C3A41"
      />
      <path
        d="M48.0267 2.03296h-1.6908V15.2376h1.6908V2.03296z"
        fill="#F16935"
      />
      <path
        d="M54.4873 18.2947h-4.5049v1.015h4.5049v-1.015zM44.6924 18.2947h-4.5049v1.015h4.5049v-1.015z"
        fill="#F9F8F7"
      />
      <path d="M47.4757 15.2407h-.5851v16.32h.5851v-16.32z" fill="#C55629" />
      <path
        d="M119.264 23.0481h-2.616v4.5137h2.616v-4.5137zM3.95698 23.0481H1.3418v4.5137h2.61518v-4.5137z"
        fill="#F9F8F7"
      />
      <path
        d="M101.585 47.3891c5.929 0 10.736-4.8065 10.736-10.7357 0-5.9291-4.807-10.7357-10.736-10.7357-5.9289 0-10.7354 4.8066-10.7354 10.7357 0 5.9292 4.8065 10.7357 10.7354 10.7357z"
        fill="#3A4D57"
      />
      <path
        d="M101.585 42.3694c3.157 0 5.716-2.5591 5.716-5.7159 0-3.1569-2.559-5.716-5.716-5.716-3.1567 0-5.7159 2.5591-5.7159 5.716 0 3.1568 2.5592 5.7159 5.7159 5.7159z"
        fill="#BFC1BF"
      />
      <path
        d="M101.973 35.8695h-.781l-.272-3.3991c-.007-.0918.004-.1842.034-.2713.03-.0871.078-.167.14-.2347.062-.0676.138-.1216.223-.1584.084-.0368.175-.0556.267-.0553.092.0001.183.0192.267.0562.084.037.16.091.222.1586.062.0677.109.1474.139.2343.03.0869.041.179.033.2706l-.272 3.3991z"
        fill="#3A4D57"
      />
      <path
        d="M101.309 35.8226l-.553.5529-2.5942-2.2115c-.0698-.0593-.1266-.1324-.1667-.2146-.0402-.0823-.0629-.172-.0668-.2635-.0038-.0915.0113-.1828.0444-.2681.0331-.0854.0836-.163.1481-.2279.0648-.0652.1424-.1162.2279-.1499.0855-.0336.1771-.0492.269-.0456.0918.0036.1819.0262.2646.0664.0826.0402.156.0971.2155.1671l2.2112 2.5947z"
        fill="#3A4D57"
      />
      <path
        d="M100.802 36.2732v.781l-3.3993.2721c-.0913.0073-.1831-.0044-.2697-.0344-.0865-.0299-.1659-.0774-.2332-.1395-.0674-.0621-.1211-.1374-.1579-.2212-.0368-.0839-.0558-.1745-.0559-.266-.0034-.0943.0135-.1883.0493-.2756.0359-.0873.0899-.166.1586-.2308.0686-.0647.1503-.1141.2395-.1449.0893-.0307.1841-.042.278-.0332l3.3906.2925z"
        fill="#3A4D57"
      />
      <path
        d="M100.757 36.9285l.553.5528-2.212 2.5947c-.0593.0703-.1325.1274-.2151.1679-.0825.0404-.1725.0633-.2644.0672-.0918.0038-.1835-.0114-.2691-.0449-.0857-.0334-.1634-.0842-.2284-.1492-.0646-.0652-.1151-.143-.1482-.2286-.0332-.0856-.0483-.1771-.0445-.2689.0039-.0917.0266-.1816.0668-.2641.0402-.0825.097-.1559.1669-.2154l2.595-2.2115z"
        fill="#3A4D57"
      />
      <path
        d="M101.192 37.4434h.781l.272 3.3991c.007.0913-.004.1831-.034.2696-.03.0866-.078.166-.14.2333-.062.0673-.137.121-.221.1578-.084.0368-.174.0559-.266.056-.092.0003-.183-.0185-.267-.0551-.084-.0366-.16-.0903-.222-.1576-.062-.0674-.11-.1469-.14-.2337-.03-.0867-.042-.1788-.035-.2703l.272-3.3991z"
        fill="#3A4D57"
      />
      <path
        d="M101.859 37.4813l.553-.5528 2.604 2.2115c.07.0593.127.1325.168.2151.04.0825.063.1725.067.2644.004.0918-.012.1835-.045.2691-.033.0856-.084.1634-.149.2284-.065.065-.143.1158-.229.1492-.085.0335-.177.0487-.269.0449-.092-.0039-.182-.0268-.264-.0672-.083-.0405-.156-.0976-.215-.1679l-2.221-2.5947z"
        fill="#3A4D57"
      />
      <path
        d="M102.367 37.0425v-.7693l3.399-.2721c.091-.0068.181.005.267.0346.086.0296.164.0763.231.1374.067.0611.121.1353.158.2179.037.0826.057.1719.058.2625 0 .0915-.019.1821-.056.2659-.037.0839-.09.1592-.158.2213-.067.0621-.146.1096-.233.1395-.087.0299-.178.0416-.27.0344l-3.396-.2721z"
        fill="#3A4D57"
      />
      <path
        d="M102.412 36.3755l-.553-.5529 2.212-2.5947c.059-.07.133-.1269.215-.1671.083-.0402.173-.0628.265-.0664.092-.0036.183.012.269.0456.086.0337.163.0847.228.1499.065.0647.116.1422.149.2276.033.0854.049.1768.045.2685-.004.0916-.027.1814-.067.2637-.041.0823-.098.1553-.168.2143l-2.595 2.2115zM26.4747 38.8386c1.362-5.7707-2.2119-11.5528-7.9826-12.9148-5.7706-1.362-11.55268 2.2119-12.91467 7.9826-1.36199 5.7706 2.2119 11.5527 7.98247 12.9147 5.7707 1.362 11.5528-2.2119 12.9148-7.9825z"
        fill="#3A4D57"
      />
      <path
        d="M21.5857 37.6855c.7252-3.0724-1.1777-6.151-4.2501-6.8761-3.0724-.7252-6.1509 1.1776-6.8761 4.2501-.72516 3.0724 1.1777 6.1509 4.2501 6.8761 3.0724.7251 6.151-1.1777 6.8761-4.2501z"
        fill="#BFC1BF"
      />
      <path
        d="M16.4146 35.5946h-.7811l-.272-3.3991c-.0073-.0916.0045-.1836.0346-.2704.0301-.0867.0778-.1663.1402-.2336.0624-.0674.1381-.121.2223-.1577.0842-.0366.1751-.0553.2669-.055.0916.0001.1821.0191.266.0559.0838.0368.1592.0906.2213.1579.062.0673.1095.1467.1395.2332.0299.0866.0416.1784.0343.2697l-.272 3.3991z"
        fill="#3A4D57"
      />
      <path
        d="M15.7478 35.548l-.5382.5529-2.5947-2.2115c-.0701-.0595-.127-.1329-.1672-.2156-.0402-.0826-.0628-.1727-.0663-.2646-.0036-.0918.0119-.1834.0456-.2689.0336-.0856.0847-.1632.1499-.2279.0645-.0652.1418-.1162.2271-.1498.0853-.0336.1767-.0491.2683-.0455.0916.0035.1814.0261.2639.0663.0824.0402.1555.097.2148.167l2.1968 2.5976z"
        fill="#3A4D57"
      />
      <path
        d="M15.2428 35.9807v.781l-3.3963.2721c-.0921.0089-.185-.0014-.2729-.0304-.0879-.0289-.1688-.0758-.2376-.1377-.0688-.0619-.1239-.1375-.1619-.2218-.038-.0844-.0581-.1758-.0589-.2683.0001-.0918.0192-.1827.0562-.2667.037-.0841.091-.1596.1586-.2217.0677-.0621.1474-.1096.2343-.1393.0869-.0297.179-.0411.2705-.0334l3.408.2662z"
        fill="#3A4D57"
      />
      <path
        d="M15.2113 36.6536l.5529.5528-2.2115 2.5947c-.0592.07-.1324.1269-.2148.167-.0824.0402-.1723.0628-.2639.0664-.0916.0035-.1829-.012-.2682-.0456-.0853-.0336-.1627-.0846-.2272-.1497-.0651-.0646-.1161-.1419-.1497-.2272-.0336-.0853-.0492-.1766-.0456-.2683.0036-.0916.0262-.1814.0664-.2638.0401-.0824.097-.1556.1669-.2148l2.5947-2.2115z"
        fill="#3A4D57"
      />
      <path
        d="M15.6357 37.1506h.781l.2721 3.3992c.0077.0915-.0037.1836-.0334.2705-.0297.0869-.0772.1667-.1393.2343-.0621.0676-.1376.1216-.2217.1586-.0841.037-.1749.0561-.2667.0562-.0921.0003-.1832-.0185-.2676-.0553-.0845-.0368-.1603-.0907-.2227-.1584-.0625-.0677-.1102-.1476-.1401-.2347-.0299-.0871-.0413-.1795-.0336-.2712l.272-3.3992z"
        fill="#3A4D57"
      />
      <path
        d="M16.3027 37.2064l.5529-.5528 2.5947 2.2115c.0702.059.1273.132.1677.2143.0404.0823.0633.1721.0671.2637.0039.0916-.0114.183-.0447.2684-.0334.0854-.0842.163-.1491.2277-.0648.0652-.1424.1162-.228.1499-.0855.0336-.1771.0491-.2689.0456-.0918-.0036-.1819-.0262-.2646-.0664-.0826-.0402-.1561-.0971-.2156-.1672l-2.2115-2.5947z"
        fill="#3A4D57"
      />
      <path
        d="M16.8086 36.7676v-.7869l3.3991-.2721c.0913-.0072.1831.0045.2697.0344.0865.0299.1659.0774.2332.1395.0673.0621.1211.1374.1579.2213.0368.0838.0558.1744.0559.2659-.0001.0917-.0192.1824-.0559.2664-.0368.084-.0905.1595-.1577.2218-.0673.0623-.1467.1101-.2332.1404-.0866.0302-.1784.0423-.2699.0355l-3.3991-.2662z"
        fill="#3A4D57"
      />
      <path
        d="M16.8556 36.1006l-.5529-.5529 2.2115-2.5976c.0595-.07.133-.127.2156-.1672.0827-.0402.1728-.0628.2646-.0663.0918-.0036.1834.0119.2689.0456.0856.0336.1632.0847.228.1499.065.0649.1158.1427.1492.2283.0334.0857.0487.1773.0448.2692-.0038.0918-.0267.1819-.0672.2644-.0404.0825-.0976.1558-.1678.2151l-2.5947 2.2115z"
        fill="#3A4D57"
      />
    </svg>
  )
}

export default Logo
